import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Usage`}</h2>
    <p>{`Use an action bar to render multiple buttons in a row. Buttons can be split into groups by adding a divider. When there is not enough space, buttons that don't fit will be added to an overflow menu.`}</p>
    <h2>{`Anatomy`}</h2>
    <img width="960" alt="A diagram of an action bar with a few buttons, a divider and at the end a button that opens an overflow menu." src="https://user-images.githubusercontent.com/378023/193506131-be8bead4-d1ab-4c79-8ac1-054e75bac9be.png" />
    <h2>{`Content`}</h2>
    <h3>{`Buttons`}</h3>
    <p>{`Buttons are the only controls allowed in an action bar. Even though a `}<a parentName="p" {...{
        "href": "/components/segmented-control"
      }}>{`segmented control`}</a>{` is technically a group of buttons, it does not belong in an action bar because it's used for selecting an option, not triggering an action.`}</p>
    <p>{`Buttons that open `}<a parentName="p" {...{
        "href": "/components/dialog"
      }}>{`dialogs`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/action-menu"
      }}>{`action menus`}</a>{` or `}<a parentName="p" {...{
        "href": "/components/select-panels"
      }}>{`select panels`}</a>{` are acceptable in an action bar becacuse they perform an action: to open a new context.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/primer/design/assets/2313998/556243b5-ba71-44cd-a0a2-710a6aaa4bf5" role="presentation" width="456" />
    <Caption mdxType="Caption">Only use buttons in an action bar.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://github.com/primer/design/assets/2313998/44a40b05-d970-4a47-877e-fb8ae5ae54b2" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't use other components in an action bar.</Caption>
  </Dont>
    </DoDontContainer>
    <p>{`Icon buttons are the default, but buttons may also have text labels. All buttons in an action bar should have the same type of content (icon, text label, or text label with icon).`}</p>
    <p>{`The button that opens the overflow menu will always be an icon button with a `}<a parentName="p" {...{
        "href": "/foundations/icons/kebab-horizontal-16"
      }}>{`"kebab" icon`}</a>{`.`}</p>
    <img src="https://github.com/primer/design/assets/2313998/d3ef0af6-034a-4c55-a4ea-c2fb239ddccd" role="presentation" width="456" />
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/primer/design/assets/2313998/2cc69c4c-d81f-49d7-8ac2-df5b9f191d22" role="presentation" width="456" />
    <Caption mdxType="Caption">Use either icons or text labels, but not both in the same action bar.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://github.com/primer/design/assets/2313998/4d5726e7-2ef6-4112-8b23-69c4cf129c26" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't mix types of button labels within the same action bar.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Dividers`}</h3>
    <p>{`Dividers can be added to visually group related buttons.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/378023/193506858-6542a6ba-4bac-400d-bba9-8601fbc032ed.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Use a divider between buttons.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/378023/193506894-dc0a65a0-0f81-444a-aa8c-f3b0f74aecb5.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't use a divider at the beginning or end of the action bar.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Overflow menu`}</h3>
    <p>{`When the buttons don't fit in the available horizontal space, they're moved to an overflow menu. An icon button (`}<a parentName="p" {...{
        "href": "/foundations/icons/kebab-horizontal-16"
      }}>{`"kebab" icon`}</a>{`) is added at the end of the action bar signaling that there are more actions available. Clicking on the overflow button opens a menu with the remaining actions that didn't fit.`}</p>
    <p>{`Dividers from the action bar are preserved in the overflow menu.`}</p>
    <img src="https://user-images.githubusercontent.com/378023/193507064-4efe3f63-7b30-4656-8304-3dea3e3f1e03.png" alt="" width="960" />
    <h4>{`Sorting`}</h4>
    <p>{`Buttons that are moved to the overflow menu because they don't fit in the available width get added to the top of the menu. Meaning that the last button in the action bar will also be the last button when inside the menu.`}</p>
    <img src="https://user-images.githubusercontent.com/378023/188835345-0cfd3376-1658-496f-a78b-f5977aa2198c.png" alt="" width="960" />
    <h4>{`Demo`}</h4>
    <CustomVideoPlayer width="720px" loop src="https://user-images.githubusercontent.com/378023/188359460-bc88bac8-9c69-4aea-8ce0-bc427bedc3a3.mov" poster="https://user-images.githubusercontent.com/378023/188529873-eafbad89-9eeb-4342-905d-15d89d288ffd.png" mdxType="CustomVideoPlayer" />
    <Caption mdxType="Caption">Overflow button appears when not enough space and resizing the action bar updates the overflow menu.</Caption>
    <h2>{`States`}</h2>
    <h3>{`Button states`}</h3>
    <p>{`Buttons in action bars are solely used for triggering actions. Consider using a `}<a parentName="p" {...{
        "href": "/components/segmented-control"
      }}>{`segmented control`}</a>{` when a button should have a selected state.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/378023/193506579-a665b941-5b4e-4e44-9439-cfb4e7e93ef3.png" role="presentation" width="456" />
    <Caption mdxType="Caption">
      Buttons in action bars have a hover and pressed state, and a focused state when using a keyboard to navigate.
    </Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/378023/193506649-07cb8b8b-3658-477a-b7a2-f00ad9825f26.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't add a selected state or any other information like a notification dot or a counter.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Tooltips`}</h3>
    <p>{`When hovering over a button, a tooltip will appear that describes the action.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/378023/193506950-1702cff8-c751-4bc2-a37a-a837d1e69320.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Describe what action will be taken when clicking on the button.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/378023/193506979-07aa35d2-48f5-4b09-aa3e-c575be0e578e.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't use a tooltip in action bars to convey a current state.</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Options`}</h2>
    <h3>{`Size`}</h3>
    <p>{`Action bars can have 3 different sizes:`}</p>
    <img src="https://user-images.githubusercontent.com/378023/193507132-f3ad4632-e257-4301-bd48-0669f4347ddc.png" role="presentation" width="960" />
    <ul>
      <li parentName="ul">{`Small (`}<inlineCode parentName="li">{`28px`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`Medium (`}<inlineCode parentName="li">{`32px`}</inlineCode>{`) (default)`}</li>
      <li parentName="ul">{`Large (`}<inlineCode parentName="li">{`40px`}</inlineCode>{`)`}</li>
    </ul>
    <h3>{`Button variants`}</h3>
    <p>{`Icon buttons should default to the `}<inlineCode parentName="p">{`invisible`}</inlineCode>{` (no border/background) variant. Buttons with text labels should default to the `}<inlineCode parentName="p">{`secondary`}</inlineCode>{` variant.`}</p>
    <p>{`You may use the `}<inlineCode parentName="p">{`danger`}</inlineCode>{` variant for destructive actions. The `}<inlineCode parentName="p">{`primary`}</inlineCode>{` variant should not be used in an action bar because primary page actions are highly unlikely to appear in a collection with other actions.`}</p>
    <h2>{`Layout`}</h2>
    <p>{`Action bars can be used inline next to other content or also full width taking up the entire space.`}</p>
    <Box as="p" mdxType="Box">
  <img src="https://user-images.githubusercontent.com/378023/193507204-7d2e1e10-8906-49b4-9c80-10d69fce3e92.png" role="presentation" width="960" />
    </Box>
    <h3>{`Spacing`}</h3>
    <p>{`Make sure to add extra spacing around the action bar.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/378023/193507240-00801a0a-053b-45e3-9896-9d47150f8a99.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Extra padding of 8px is added when nesting an action bar in a box component.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/378023/193507276-25dbece0-ea90-4050-8333-2a7891dee8ef.png" role="presentation" width="456" />
    <Caption mdxType="Caption">
      Avoid having the action bar touch something else. Even though the action bar buttons have no borders in their
      resting state, when hovering/pressing a button it will show a background color.
    </Caption>
  </Dont>
    </DoDontContainer>
    <p>{`Icon buttons using the `}<inlineCode parentName="p">{`invisible`}</inlineCode>{` variant have no space between them. Button variants with a background or a border require space between them so they're not right on top of eachother.`}</p>
    <img src="https://github.com/primer/design/assets/2313998/6888cee3-5d24-4ec5-b141-8d7175a27d62" role="presentation" width="456" />
    <h2>{`Accessibility`}</h2>
    <h3>{`Role`}</h3>
    <p>{`An action bar has an ARIA role of `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/toolbar_role"
      }}><inlineCode parentName="a">{`toolbar`}</inlineCode></a>{` by default. The `}<inlineCode parentName="p">{`toolbar`}</inlineCode>{` role only has a single tab stop. Focus is moved within the action bar using the left and right arrow keys, the "Home" key, and the "End" key.`}</p>
    <h3>{`Keyboard navigation for action bars with a role of `}<inlineCode parentName="h3">{`toolbar`}</inlineCode></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Tab`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Moves focus into and out of the action bar. Note that there should only be `}<strong parentName="td">{`one tab-stop`}</strong>{` and pressing tab again should focus the next focusable element after the action bar. Also the first button is focused if the action bar is receiving focus for the first time after page load. Otherwise, the most recently focused button receives focus.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`→`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Right arrow moves focus to the `}<strong parentName="td">{`next`}</strong>{` button. If the last button has focus, focus loops back to the first button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`←`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Left arrow moves focus to the `}<strong parentName="td">{`previous`}</strong>{` button. If the first button has focus, focus moves to the last button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Home`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Moves focus to the `}<strong parentName="td">{`first`}</strong>{` button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`End`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Moves focus to the `}<strong parentName="td">{`last`}</strong>{` button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="td">{`Space`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Triggers the button `}<strong parentName="td">{`action`}</strong>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The `}<inlineCode parentName="p">{`toolbar`}</inlineCode>{` role should be overridden if you're using an action bar in a context where the behavior of left arrow, right arrow, "Home", and "End" keys conflict with their behavior in a `}<inlineCode parentName="p">{`toolbar`}</inlineCode>{`. For example, as list item actions within a list where focus may be moved between items using arrow, "Home", and "End" keys.`}</p>
    <p>{`In cases where we don't use the `}<inlineCode parentName="p">{`toolbar`}</inlineCode>{` role, no role is applied and each buttons has one tab stop (their default keyboard navigation behavior).`}</p>
    {/* TODO: Add video */}
    <h3>{`Touch targets`}</h3>
    <p>{`When the user is on a device with coarse pointer (such as a touch screen), the button target needs to be enlarged so users can easily tap the correct target button.`}</p>
    <Box as="figure" display="flex" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Text as="p" mt="0" mdxType="Text">
    Ensure action bar buttons have a large enough touch target size (44px by 44px). The buttons should respond to
    hovers, clicks, and taps anywhere in the touch target area, even if it isn't directly on the control. To avoid
    overlapping of touch targets, additional space between each button is needed (for example a 12px gap for medium
    sized buttons).
  </Text>
  <img src="https://user-images.githubusercontent.com/378023/193507314-1f1cc55d-ca4e-4832-a6de-785546e079ca.png" role="presentation" width="456" />
    </Box>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
    <AccessibilityLink label="ActionBar" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      